import React, {Component, useRef} from 'react';
import CurrencyInput, { formatValue } from 'react-currency-input-field';
import config from "./client.config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDeleteLeft,
    faEuro, faFileImage,
    faFileLines,
    faFilePdf,
    faImage,
    faQrcode, faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Dropdown from "react-bootstrap/Dropdown";
import ProductDetailsPopup from "./components/ProductDetailsPopup";
import Button from "react-bootstrap/Button";
import APIController from "./controller/APIController";
import ProductsTableResponsiveWrapper from "./ProductsTableResponsiveWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AuslaufArtikelIcon from "./components/AuslaufArtikelIcon";


class ProductsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            products : [],
            menuFilter: props.menuFilter
        };

        this.state.downloadPdfAr = props.downloadPdfAr;
        this.state.downloadPdfWeb = props.downloadPdfWeb;
        this.state.downloadImages = props.downloadImages;
        this.state.corporateIdentity = props.corporateIdentity;

        this.state.priceOverrides = Object.create({});
        this.state.beautifyBruttoPrices = false;

    }


    reload = async (filter) => {
        try{
            console.log("fetch data");
            var data = await this.fetchData(filter);
            console.log("finish");
            let userPrices = await APIController.getUserPrices(filter.merchant_id);
            let beautifyBruttoPrices = await APIController.getUserPricesBeautifyMode(filter.merchant_id);

            this.props.onPaginationCountChanged(data.paginationInfo.results);
            this.setState({
                products: data.products,
                priceOverrides: userPrices,
                beautifyBruttoPrices: beautifyBruttoPrices
            });

        }catch (e) {
            this.props.onPaginationCountChanged(0);
            this.setState({products: []});
            console.log("Error while fetching products!");
        }
    }



    componentDidUpdate(props) {
        this.state.menuFilter = props.menuFilter;
        return this.state;
    }

    componentDidMount() {
        this.reload(this.state.menuFilter);
    }


    fetchData = async (filter) => {

        const merchant_id = encodeURIComponent(filter.merchant_id);
        const manufacturer_id = encodeURIComponent(filter.manufacturer_id);
        const productgroup_id = encodeURIComponent(filter.productgroup_id);
        const productsubgroup_id = encodeURIComponent(filter.productsubgroup_id);
        const loadCachedData = encodeURIComponent(filter.loadCachedData);

        const product_id = encodeURIComponent(filter.product_id);
        const searchString = encodeURIComponent(filter.searchString);

        var pageEntriesCount = filter.pagination_max_entries;

        const params = new URLSearchParams({
            from: (filter.pagination_current_page * pageEntriesCount) - pageEntriesCount,
            count: pageEntriesCount,
            merchant_id: merchant_id,
            manufacturer_id: manufacturer_id,
            productgroup_id: productgroup_id,
            productsubgroup_id: productsubgroup_id,
            product_id: product_id,
            searchString: searchString,
            loadCachedData: loadCachedData
        });

        this.setState({ isFetching: true});
        const res = await fetch(config.SERVER_API_URL + "/products?" + params.toString()).catch((error) => {
            console.log('error: ' + error);
        });
        this.setState({ isFetching: false});
        if(!res.ok){
            throw new Error(res.status);
        }else{
            return res.json();
        }
    };


    /**
     * This functions makes all prices in cent beautifuly with setting
     * the number after comma to 95.
     * Examples:
     * 7027 => 6995 = 69,95€
     * 7049 => 6995 = 69,95€
     * 7050 => 7095 = 70,95€
     * 7099 => 7095 = 70,95€
     * 7100 => 7095 = 70,95€
     * 7096 => 7095 = 70,95€
     * 7214 => 7195 = 71,95€
     * 10050 => 10095 = 100,95€
     * 10049 => 9995 = 99,95€
     * @param priceValueInCent like 50 '50cent' or 7093 '70,93€'
     * @returns {number} like 7093
     */
    beautifyPriceNumber(priceValueInCent){
        let stringVal = ""+(priceValueInCent / 100.0).toFixed(2);
        let priceAfterCommaInCent = (parseInt(stringVal.split(".")[1]));
        let priceBeforeCommaInEuro = stringVal.split(".")[0];
        if(priceBeforeCommaInEuro > 1 && priceAfterCommaInCent < 50){
            priceBeforeCommaInEuro = priceBeforeCommaInEuro -1;
        }
        priceAfterCommaInCent = 95;
        return parseFloat(priceBeforeCommaInEuro+"."+priceAfterCommaInCent) * 100;
    }

    reverseFormatNumber(val, locale) {
        var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
        var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
        var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
        reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    }

    renderTableOld(){

        const download_params = new URLSearchParams({
            merchant_id: encodeURIComponent(this.state.menuFilter.merchant_id),
            manufacturer_id: encodeURIComponent(this.state.menuFilter.manufacturer_id),
            productgroup_id: encodeURIComponent(this.state.menuFilter.productgroup_id),
            productsubgroup_id: encodeURIComponent(this.state.menuFilter.productsubgroup_id),
            product_id: encodeURIComponent(this.state.menuFilter.product_id),
            loadCachedData: encodeURIComponent(this.state.menuFilter.loadCachedData)
        });

        let counter = 0;

        const centeredText = {
            textAlign: 'center'
        }

        return (
            <div className="productsTableBody">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr>
                            <td style={{width: '8em'}}>
                                <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                    <FontAwesomeIcon icon={faQrcode}/> QR-Code
                                </div>
                            </td>
                            <td>
                                <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                    <FontAwesomeIcon icon={faFileLines}/> Artikel
                                </div>
                            </td>
                            <td style={{textAlign: 'center', paddingRight: '3.5em'}}>
                                <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                    <FontAwesomeIcon icon={faEuro}/> Brutto Listenpreis
                                </div>
                            </td>
                            <td style={{textAlign: 'center', paddingRight: '3em', paddingLeft: '0em', width: '8em',}}>
                                {this.state.beautifyBruttoPrices &&
                                    <div style={{paddingTop: '0.2em', paddingBottom: '0.2em'}}>
                                        Brutto Listenpreis inkl. MwSt.
                                        {/*
                                                <Button style={{padding:'0.2em',paddingLeft:'0.3em',
                                                        marginLeft:'0.5em',
                                                        paddingRight:'0.3em'}} onClick={()=>{
                                                        this.setState({
                                                            beautifyBruttoPrices:false
                                                        });
                                                        APIController.updateUserPricesBeautifyMode(self.state.menuFilter.merchant_id
                                                            ,false).then(r => {
                                                            console.log(r)
                                                        });
                                                    }}  variant='primary'>
                                                        <FontAwesomeIcon icon={faStar}/>
                                                    </Button>
                                                 */}
                                    </div>

                                }
                                {!this.state.beautifyBruttoPrices &&
                                    <div style={{paddingTop: '0.22em', paddingBottom: '0.22em'}}>
                                        Brutto Listenpreis inkl. MwSt.
                                        {/*
                                                <Button style={{padding:'0.12em',paddingLeft:'0.3em',
                                                    marginLeft:'0.5em',
                                                    paddingRight:'0.3em'}} variant='light' onClick={()=>{
                                                        this.setState({
                                                            beautifyBruttoPrices:true
                                                        })
                                                        APIController.updateUserPricesBeautifyMode(self.state.menuFilter.merchant_id
                                                            ,true).then(r => {
                                                            console.log(r)
                                                        });
                                                    }
                                                    }>
                                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                                </Button>
                                                */}
                                    </div>
                                }
                            </td>
                            <td>
                                <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                    <FontAwesomeIcon icon={faImage}/> Anhang
                                </div>
                            </td>
                            <td style={{textAlign: 'center'}}>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.products.map(function (object, idx) {
                                counter++;
                                const childRef = React.createRef();
                                return <tr key={"p_" + idx}>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"}
                                        style={{paddingLeft: '1em', width: 'auto', verticalAlign: 'middle'}}>
                                    </td>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"}
                                        style={{verticalAlign: 'middle'}}>
                                        </td>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"} style={{
                                        verticalAlign: 'middle',
                                        width: '9em',
                                        fontSize: '1.2em'
                                    }}>
                                    </td>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"} style={{
                                        verticalAlign: 'middle',
                                        width: '8em',
                                        paddingRight: '3em',
                                        fontSize: '1.2em'
                                    }}>
                                    </td>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"}> </td>
                                    <td className={(counter % 2) == 0 ? "evenClass" : "oddClass"} style={{...centeredText}}>


                                    </td>
                                </tr>
                            }
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderPriceInputCell(product,idx){
        const self = this;
        let priceValues = self.calcPriceValues(product);
        return (
            <div style={{textAlign: 'center'}}>
                <CurrencyInput
                    style={{
                        width: '6em',
                        textAlign: 'center',
                        background: self.state.priceOverrides.hasOwnProperty(
                            product.uniqueId) ? 'white' : 'white',
                        color: self.state.priceOverrides.hasOwnProperty(product.uniqueId) ? '#222' : '#222',
                        borderRadius: '1em'
                    }}
                    intlConfig={{locale: 'de-DE', currency: 'EUR'}}
                    key={idx + "_" + product.human_readable.price.valInEuro}
                    name={product.uniqueId}
                    placeholder={product.human_readable.price.valInEuro + " €"}
                    defaultValue={self.state.priceOverrides.hasOwnProperty(product.uniqueId) ?
                        self.state.priceOverrides[product.uniqueId] :
                        product.human_readable.price.valInEuro
                    }
                    value={self.state.priceOverrides.hasOwnProperty(product.uniqueId) ?
                        self.state.priceOverrides[product.uniqueId] :
                        product.human_readable.price.valInEuro
                    }
                    maxLength={6}
                    decimalsLimit={2}
                    onValueChange={function (value, name) {
                        if (value == undefined) {
                            self.state.priceOverrides[name] = "0,00";
                            self.setState(self.state.priceOverrides);
                            APIController.updateUserPrices(self.state.menuFilter.merchant_id
                                , self.state.priceOverrides).then(r => {
                                //self.forceUpdate();
                            });
                        } else {
                            let rawFloatValue = parseFloat(self.reverseFormatNumber(value, "de")).toFixed(2)
                            if (rawFloatValue != product.human_readable.price.valInEuro) {
                                self.state.priceOverrides[name] = value;
                                self.setState(self.state.priceOverrides);
                                APIController.updateUserPrices(self.state.menuFilter.merchant_id
                                    , self.state.priceOverrides).then(r => {
                                    console.log(r);
                                    //self.forceUpdate();
                                });
                            }
                        }
                    }
                    }
                />
                {!self.state.priceOverrides.hasOwnProperty(product.uniqueId) &&
                    <div style={{width: '1.7em', height: '1em', display: 'inline-block'}}></div>
                }
                {self.state.priceOverrides.hasOwnProperty(product.uniqueId) &&
                    <FontAwesomeIcon onClick={() => {
                        delete self.state.priceOverrides[product.uniqueId];
                        self.setState(self.state.priceOverrides);
                        APIController.updateUserPrices(self.state.menuFilter.merchant_id
                            , self.state.priceOverrides).then(r => {
                            console.log(r)
                        });
                    }
                    }style={{marginLeft: '0.5em'}} icon={faDeleteLeft}/>
                }
            </div>
        )
    }

    calcPriceValues(product){
        const self = this;
        let priceNettoValue = self.state.priceOverrides.hasOwnProperty(product.uniqueId)?
            parseFloat(self.reverseFormatNumber(self.state.priceOverrides[product.uniqueId],"de")).toFixed(2):
            product.human_readable.price.valInEuro;
        let priceBrutto = (parseFloat(priceNettoValue) + (parseFloat(priceNettoValue)*0.19)).toFixed(2);
        if(self.state.beautifyBruttoPrices){
            priceBrutto = (self.beautifyPriceNumber(priceBrutto*100.0) / 100);
        }
        let priceConsumerBeautified = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
            priceBrutto
        )
        let priceBruttoValue = priceBrutto.toString().replace(".",",")
        priceConsumerBeautified += "/";
        priceConsumerBeautified += (product['ME']+"").toLowerCase();
        priceConsumerBeautified += " inkl. MwSt";
        return {
            priceNettoValue: priceNettoValue,
            priceBruttoValue: priceBruttoValue,
            priceConsumerBeautified: priceConsumerBeautified
        }
    }


    renderPriceCell(product,idx){
        const self = this;
        let priceValues = self.calcPriceValues(product);
        return (
            <div>
                <CurrencyInput
                    style={{
                        width: '6em',
                        textAlign: 'center',
                        borderRadius: '1em',
                        border: self.state.beautifyBruttoPrices ? '1px solid #0126a8' : '1px solid #f1f1f1',
                    }}
                    intlConfig={{locale: 'de-DE', currency: 'EUR'}}
                    id="input-example"
                    key={idx + "_brutto"}
                    disabled={true}
                    name={product.uniqueId + "_brutto"}
                    placeholder={product.human_readable.price.valInEuro + " €"}
                    value={priceValues.priceBruttoValue}
                    decimalsLimit={2}
                />
            </div>
        )
    }

    renderQRCodeCell(download_params,product){
        const self = this;
        return (
            <>
                {(self.state.downloadPdfAr === true || self.state.downloadPdfWeb === true) &&
                    <Dropdown className="qrcodeImageDownloadDropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {(self.state.downloadPdfAr === true) ?
                                <div>
                                    <a className={'qrCodeDownloadImages'}>
                                        <img src={product.uniqueIdArCodeUrl} width={43}/>
                                        <img src="/images/qrcode_example_4GNWEA9X05.png"
                                             width={45}/>
                                    </a>
                                </div> :
                                <div>
                                    <a className={'qrCodeDownloadImages'}>
                                        <img src="/images/qrcode_example_4GNWEA9X05.png"
                                             width={45}/>
                                    </a>
                                </div>
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {self.state.downloadPdfAr === true &&
                                <Dropdown.Item target={"_blank"}
                                               href={config.SERVER_API_URL + "/products?" +
                                                   download_params.toString() + "&outFormat=PDF&outFormatTemplate=AR_APP&searchString=" + product.uniqueId}>
                                    <FontAwesomeIcon icon={faFilePdf}/> QR-Code (AR-App)
                                </Dropdown.Item>
                            }
                            {self.state.downloadPdfWeb === true &&
                                <Dropdown.Item target={"_blank"}
                                               href={config.SERVER_API_URL + "/products?" +
                                                   download_params.toString() + "&outFormat=PDF&outFormatTemplate=WEB_APP&searchString=" + product.uniqueId}>
                                    <FontAwesomeIcon icon={faFilePdf}/> QR-Code (Web-App)
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </>
        );
    }

    renderImageGallery(product){
        const self = this;
        return (
            <>
                {product.image_files.thumbnail.map((image_url, i) => {
                    var filename = image_url.substring(image_url.lastIndexOf('/') + 1);
                    return (
                        <div className={'imagebox'}>

                            {(self.state.downloadImages === true) ?
                                <div>
                                    <Dropdown className="qrcodeImageDownloadDropdown">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <img title={filename} alt={filename} height={'40px'}
                                                 src={image_url}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item target={"_blank"}
                                                           href={product.image_files.highResolution[i]}>
                                                <FontAwesomeIcon icon={faFileImage}/> Hochauflösend
                                            </Dropdown.Item>
                                            <Dropdown.Item target={"_blank"}
                                                           href={product.image_files.large[i]}>
                                                <FontAwesomeIcon icon={faFileImage}/> Normal
                                            </Dropdown.Item>
                                            <Dropdown.Item target={"_blank"}
                                                           href={product.image_files.thumbnail[i]}>
                                                <FontAwesomeIcon icon={faFileImage}/> Vorschaubild
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> :
                                <div>
                                    <img title={filename} alt={filename} height={'60px'}
                                         src={image_url}/>
                                </div>
                            }
                        </div>
                    )
                })}
            </>
        )
    }

    renderShowDetailsCell(product,childRef){
        const self = this;
        let priceValues = self.calcPriceValues(product);
        return (<>
            <Button style={{textDecoration: 'none', fontWeight: 'bold', letterSpacing: '1px'}}
                    onClick={() => childRef.current.handleShow()}>
                <FontAwesomeIcon icon={faSearch}/> Details
            </Button>
            <ProductDetailsPopup
                downloadCsv={self.state.downloadCsv}
                downloadPdfAr={self.state.downloadPdfAr}
                downloadPdfWeb={self.state.downloadPdfWeb}
                downloadImages={self.state.downloadImages}
                merchantId={self.state.menuFilter.merchant_id}
                showFooter={true}
                ref={childRef}
                priceConsumerBeautified={priceValues.priceConsumerBeautified}
                product={product}
                title={
                    <h4>
                        {product.human_readable.original_text_lines.map((artikeltext_line, i) => {
                            return (
                                <span>{artikeltext_line} </span>
                            )
                        })}
                    </h4>
                }
                show={false}>
            </ProductDetailsPopup>
        </>)
    }


    render() {
        const self = this;
        if(this.state.isFetching && this.state.products != null){
            return  <div className={'loadingContainer'} style={{backgroundImage:"url('loading.gif')",paddingBottom:"2em"}}></div>;
        }else{
            if(this.state.products.length == 0){
                return <></>
            }else{
                const download_params = new URLSearchParams({
                    merchant_id: encodeURIComponent(this.state.menuFilter.merchant_id),
                    manufacturer_id: encodeURIComponent(this.state.menuFilter.manufacturer_id),
                    productgroup_id: encodeURIComponent(this.state.menuFilter.productgroup_id),
                    productsubgroup_id: encodeURIComponent(this.state.menuFilter.productsubgroup_id),
                    product_id: encodeURIComponent(this.state.menuFilter.product_id),
                    loadCachedData: encodeURIComponent(this.state.menuFilter.loadCachedData)
                });

                return <ProductsTableResponsiveWrapper
                    tableHeadCellStyles={
                        [
                            {width: '8em'},
                            {},
                            {textAlign: 'center', paddingRight: '3.5em'},
                            {textAlign: 'center', paddingRight: '3em', paddingLeft: '0em', width: '8em',},
                            {},
                            {textAlign: 'center'}
                        ]
                    }
                    headColumnCells={[
                        <>
                            <FontAwesomeIcon icon={faQrcode}/>
                        </>,
                        <>
                            <FontAwesomeIcon icon={faFileLines}/> Artikel
                        </>,
                        <>
                            <FontAwesomeIcon icon={faEuro}/> BLP
                        </>,
                        <>
                            BLP inkl. MwSt.
                        </>,
                        <>
                            <FontAwesomeIcon icon={faImage}/> Anhang
                        </>,
                        <></>
                    ]}
                    productCellWidgetsArray={
                        this.state.products.map(function (product, idx) {
                            const childRef = React.createRef();
                            return (
                                [
                                    <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                        {self.renderQRCodeCell(download_params,product)}
                                    </div>,
                                    <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                        <div>
                                            <Row>
                                                <Col xs={1}>
                                                    <div style={{paddingTop: '0.3em', paddingBottom: '0.3em'}}>
                                                        <AuslaufArtikelIcon abgelaufen={product.AUSLAUFARTIKEL.toString()==="N"?false:true} />
                                                    </div>
                                                </Col>
                                                <Col xs={11}>
                                                    {product.human_readable.original_text_lines.map((artikeltext_line, i) => {
                                                        return (
                                                            <div><span>{artikeltext_line}</span><br/></div>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>,
                                    <div className={"bruttoPriceValField"} style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                        {self.renderPriceInputCell(product,idx)}
                                    </div>,
                                    <div className={"bruttoPriceValField"} style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                        {self.renderPriceCell(product,idx)}
                                    </div>,
                                    <div style={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                                        {self.renderImageGallery(product)}
                                    </div>,
                                    <div>
                                        {self.renderShowDetailsCell(product,childRef)}
                                    </div>
                                ]
                            );
                        })
                    }
                />
            }
        }
    }
}


export default ProductsTable;