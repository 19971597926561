class CorporateStyleController {

    static addUserspecificCorporateStylesheet(userCorporateIdentity) {

        let cssCode = `
    
            @font-face {
                font-family: fontFamilyText;
                src: url('${userCorporateIdentity.fontFamilyText}')  format('${userCorporateIdentity.fontFamilyTextType}');
            }
            @font-face {
                font-family: fontFamilyHeadlines;
                src: url('${userCorporateIdentity.fontFamilyHeadlines}')  format('${userCorporateIdentity.fontFamilyTextType}');
            }
            
            html {
              font-family: 'fontFamilyText', Tahoma, Sans-Serif !important;
            }
            
            .fontFamilyHeadlines {
                font-family: 'fontFamilyHeadlines' !important;
            }
            .fontFamilyText {
                font-family: 'fontFamilyText' !important;
            }
            h1,h2,h3,h4,h5{
                font-family: 'fontFamilyHeadlines' !important;
            }
            thead td{
                font-family: 'fontFamilyText' !important;
                letter-spacing:1px;
            }
            body,p,span,a{
                font-family: 'fontFamilyText' !important;
            }
            html,#root{
                font-family: 'fontFamilyText' !important;
                background: ${userCorporateIdentity.backgroundColorPrimary};
            }
            .primaryContainer{
              background: ${userCorporateIdentity.backgroundColorPrimary};
              color: ${userCorporateIdentity.fontColorPrimary};
            }
            
            .dropdown-menu{
                background: ${userCorporateIdentity.backgroundColorPrimary};
                color: ${userCorporateIdentity.fontColorPrimary};
            }
            
        
            a.dropdown-item{
                background: ${userCorporateIdentity.backgroundColorPrimary};
                color: ${userCorporateIdentity.accentColorPrimary};
            }
            a.dropdown-item:hover{
                background: ${userCorporateIdentity.backgroundColorPrimary};
                color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.accentColorPrimary,100)} !important;
            }

            
           .modal-title h4{
              margin:0px;
            }

            .modal-content{
              background: ${userCorporateIdentity.backgroundColorPrimary};
              color: ${userCorporateIdentity.fontColorPrimary};
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
            }

            .modal-header {
              background: white;
              color: #222 !important;
            }
            
     
            .detailsTable td {
              background: none;
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
            }
     
            .primaryContainer tr.oddClass td .btn.btn-primary,
            .productsTableBody.mobile .tablerow.oddClass .btn.btn-primary{
              background: ${userCorporateIdentity.backgroundColorTertiary};
              color: ${userCorporateIdentity.fontColorTertiary};
              border: 1px solid ${userCorporateIdentity.fontColorTertiary};
            }
            .primaryContainer tr.oddClass td .btn.btn-primary:hover,
            .productsTableBody.mobile .tablerow.oddClass .btn.btn-primary{
              background: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.backgroundColorTertiary,100)};
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.fontColorTertiary,20)};
            }
            
            .primaryContainer tr.evenClass td .btn.btn-primary,
            .productsTableBody.mobile .tablerow.evenClass .btn.btn-primary{
              background: ${userCorporateIdentity.backgroundColorSecondary};
              color: ${userCorporateIdentity.fontColorSecondary};
              border: 1px solid ${userCorporateIdentity.fontColorSecondary};
            }
            .primaryContainer tr.evenClass td .btn.btn-primary:hover,
            .productsTableBody.mobile .tablerow.evenClass .btn.btn-primary:hover{
              background: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.backgroundColorSecondary,100)};
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.fontColorSecondary,20)};
            }
            
            
        
        
            
          
            
            .productsTableBody .table tr.evenClass td,
            .productsTableBody.mobile .tablerow.evenClass{
              background: ${userCorporateIdentity.backgroundColorSecondary};
              color: ${userCorporateIdentity.fontColorSecondary};
            }
            
            .productsTableBody .table tr.evenClass td span,
            .productsTableBody.mobile .tablerow.evenClass span{
              color: ${userCorporateIdentity.fontColorSecondary};
            }
            .productsTableBody .table tr.oddClass td span,
            .productsTableBody.mobile .tablerow.oddClass span{
              color: ${userCorporateIdentity.fontColorTertiary};
            }
            
            .productsTableBody .table tr.oddClass td,
            .productsTableBody.mobile .tablerow.oddClass{
              background: ${userCorporateIdentity.backgroundColorTertiary};
              color: ${userCorporateIdentity.fontColorTertiary};
            }
            .productsTableBody .table tbody tr.oddClass td span{
              color: ${userCorporateIdentity.fontColorTertiary};
            }
            .loadingContainer{
              background-color: white !important;
              border: 5px solid ${userCorporateIdentity.accentColorPrimary};
            }
            
            select option{
              background: white;
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
              color: #222;
              font-family: 'fontFamilyText' !important;
            }
            .form-control, .form-control:disabled, input#searchString,
             select{
              background: none;
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
              color: ${userCorporateIdentity.accentColorPrimary} !important;
              font-family: 'fontFamilyText' !important;
            }
            
            .form-control::placeholder {
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.fontColorPrimary,100)};
              opacity: 1; /* Firefox */
            }
            
            .form-control:focus {
              background: none;
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
              color: ${userCorporateIdentity.accentColorPrimary} !important;
            }
            
            .primaryContainer span.searchResultItems{
              color: ${userCorporateIdentity.accentColorPrimary};
            }
            
            .btn.btn-primary{
              background: none;
              border: 1px solid ${userCorporateIdentity.accentColorPrimary};
              color: ${userCorporateIdentity.accentColorPrimary};
            }
            .btn.btn-primary:hover{
              border: 1px solid ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.accentColorPrimary,100)};
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.accentColorPrimary,100)};
            }
            
            .primaryContainer span{
              color: ${userCorporateIdentity.fontColorPrimary};
            }
            .primaryContainer a{
              color: ${userCorporateIdentity.accentColorPrimary};
            }
            .primaryContainer a:hover{
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.accentColorPrimary,150)};
            }
            
            .primaryContainer .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
              background: ${userCorporateIdentity.accentColorPrimary} !important;
            }
            
            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active{
              border-bottom: 2px solid ${userCorporateIdentity.accentColorPrimary} !important;
            }
            
            .nav-link:hover {
              color: ${userCorporateIdentity.accentColorPrimary};
              border: 1px solid ${userCorporateIdentity.accentColorPrimary} !important;
            }

            .productId span{
              background: none;
              color: ${userCorporateIdentity.accentColorPrimary};
            }
                  
            .secondaryContainer{
              background: ${userCorporateIdentity.backgroundColorSecondary};
              color: ${userCorporateIdentity.fontColorSecondary};
            }
            .secondaryContainer a{
              color: ${userCorporateIdentity.fontColorSecondary};
            }
            .secondaryContainer a:hover{
              color: ${userCorporateIdentity.accentColorSecondary};
            }
            
            .tertiaryContainer{
              background: ${userCorporateIdentity.backgroundColorTertiary};
              color: ${userCorporateIdentity.fontColorTertiary};
              
            }
            
            .tertiaryContainer a{
              color: ${userCorporateIdentity.accentColorTertiary};
            }
            .tertiaryContainer a:hover{
              color: ${CorporateStyleController.lightenDarkenColor(userCorporateIdentity.accentColorTertiary,150)};
            }
        
        `
        const blob = new Blob([cssCode], { type: "text/css" });
        const url = URL.createObjectURL(blob);
        CorporateStyleController.addCssStylesheet(url);
    }

    static addCssStylesheet(stylesheetUrl) {
        const style = document.createElement("link");
        style.href = stylesheetUrl;
        style.rel = "stylesheet";
        style.type = "text/css";
        style.async = true;
        document.head.appendChild(style);
    };

    static lightenDarkenColor(col,amt) {
        var usePound = false;
        if ( col[0] == "#" ) {
            col = col.slice(1);
            usePound = true;
        }
        var num = parseInt(col,16);
        var r = (num >> 16) + amt;
        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;
        var b = ((num >> 8) & 0x00FF) + amt;
        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;
        var g = (num & 0x0000FF) + amt;
        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;

        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }



}

export default CorporateStyleController;