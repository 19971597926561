import React, {Component} from 'react';
import Menu from "./Menu";
import ProductsTable from "./ProductsTable";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from "react-bootstrap";
import ApiController from "./controller/APIController";
import CorporateStyleController from "./controller/CorporateStyleController";



class App extends Component {


    constructor(props) {
        super(props);

        this.productTable = React.createRef();


        try{
            const login = async () => {
                const userInfo = await props.keycloak.loadUserInfo();

                //console.log(props.keycloak);
                var merchant_id = null;
                userInfo.groups.forEach(function (item, index, arr) {
                    if(item.startsWith("/Händler/")){
                        merchant_id = item.replace('/Händler/', '')
                    }
                });
                if(merchant_id == null){
                    throw new Error("Unknown merchant_id");
                }

                const search = window.location.search;
                const params = new URLSearchParams(search);
                if(params.get("merchant_id") != null){
                    merchant_id = params.get("merchant_id");
                }

                let loadCachedData = true;
                if(params.get("loadCachedData") != null){
                    loadCachedData = params.get("loadCachedData");
                }


                const corporateIdentity = await ApiController.getCorporateIdentityByMerchantId(merchant_id);


                var userPermissions = {
                    downloadCsv: false,
                    downloadPdfAr: false,
                    downloadPdfWeb: false,
                    downloadImages: false,
                    viewDatabase: false,
                    viewProfile: false
                }
                var manufacturers = [];

                userInfo.roles.forEach(function (item, index, arr) {
                    if(item.startsWith("kdb-access-")){
                        manufacturers.push(item.replace('kdb-access-', ''));
                    }else if(item.startsWith("kdb-permission-")){
                        var permissionVal = item.replace('kdb-permission-', '');
                        if(userPermissions.hasOwnProperty(permissionVal)){
                            userPermissions[permissionVal] = true;
                        }
                    }

                });

                if(manufacturers.length == 0){
                    throw new Error("Unknown manufacturers");
                }

                let selectedManufacturerId = manufacturers[0];
                if(params.get("manufacturer_id") != null){
                    selectedManufacturerId = params.get("manufacturer_id");
                }

                let pagination_max_entries = 50;
                if(params.get("pagination_max_entries") != null){
                    pagination_max_entries = params.get("pagination_max_entries");
                }


                CorporateStyleController.addUserspecificCorporateStylesheet(corporateIdentity);

                //http://localhost:3000/?merchant_id=TESTFIRMA&manufacturer_id=TESTHERSTELLER&loadCachedData=false
                this.setState({
                    logout: function () {
                        props.keycloak.logout();
                    },
                    isfetching: false,
                    error: false,
                    products : [],
                    corporateIdentity: corporateIdentity,
                    menuFilter: {
                        loadCachedData: loadCachedData,
                        merchant_id: merchant_id,//'KERAMIK_AGENTUR',
                        manufacturer_id: selectedManufacturerId,//'UNICOM_STARKER',
                        productgroup_id: '',
                        productsubgroup_id: '',
                        product_id: '',
                        searchString: '',
                        selected_merchant_id: merchant_id,//'KERAMIK_AGENTUR',
                        pagination_max_pages : 1,
                        pagination_max_entries : pagination_max_entries,
                        pagination_entries_count : 0,
                        pagination_current_page: 1
                    },
                    userPermissions: userPermissions,
                    filterOptions: {
                        merchant_ids: [merchant_id],//['KERAMIK_AGENTUR'],
                        pagination_entry_counts: [3, 10, 50, 100, 200, 500],
                        manufacturer_entries: manufacturers//['UNICOM_STARKER']
                    }
                });
            }

            this.state = {
                isfetching: true,
                error: false,
            };
            login().catch((e) => {
                console.log(e);
                this.setState({
                    error: true
                });
            });
        }catch (e) {
            this.state = {
                error: true
            };
        }
    }


    onMenuFilterChanged = async (option,value) => {
        console.log("onMenuFilterChanged");
        let newFilter = this.state.menuFilter;
        newFilter[option] = value;
        console.log(newFilter);
        await this.productTable.current.reload(newFilter);
        console.log("Table reloaded");
        this.setState({
            menuFilter: newFilter,
        });
    }

    onLogoutClicked = () => {
        this.state.logout();
    }

    onPaginationCountChanged = (newCount) => {
        let newFilter = this.state.menuFilter;

        newFilter.pagination_entries_count = newCount;

        let entriesCountFactor = newCount;
        if (entriesCountFactor == 0){
            entriesCountFactor = 1; //prevents max pages = 0
        }

        newFilter.pagination_max_pages = Math.ceil(entriesCountFactor / newFilter.pagination_max_entries);

        if(newFilter.pagination_current_page > newFilter.pagination_max_pages){
            newFilter.pagination_current_page = newFilter.pagination_max_pages;
        }

        this.setState({
            menuFilter: newFilter,
        });
    }

    render() {

        if(this.state.error){
            return  <div>Error</div>;
        }
        else if(this.state.isfetching){
            return  <div className={'loadingContainer'} style={{backgroundImage:"url('loading.gif')"}}></div>;
        }else{
            return <React.StrictMode>
                {this.state.userPermissions.viewDatabase ?
                <div className="primaryContainer" style={{marginBottom:"1em"}}>
                    <div className={"headerContainer primaryContainer"} style={{paddingBottom:'1em',margin:'0px auto',paddingTop:'1em','max-width':'1320px',textAlign:'center'}}>
                        <Menu onLogoutClicked={this.onLogoutClicked}
                              corporateIdentity={this.state.corporateIdentity}
                              downloadCsv={this.state.userPermissions.downloadCsv}
                              downloadPdfAr={this.state.userPermissions.downloadPdfAr}
                              downloadPdfWeb={this.state.userPermissions.downloadPdfWeb}
                              downloadImages={this.state.userPermissions.downloadImages}
                              filterOptions={this.state.filterOptions} menuFilter={this.state.menuFilter}
                              onMenuFilterChanged={this.onMenuFilterChanged}/>
                    </div>
                    <div style={{margin:'0px auto',padding:'none','max-width':'1320px',textAlign:'center'}}>
                        <ProductsTable
                            corporateIdentity={this.state.corporateIdentity}
                            onPaginationCountChanged={this.onPaginationCountChanged}
                            ref={this.productTable}
                            downloadCsv={this.state.userPermissions.downloadCsv}
                            downloadPdfAr={this.state.userPermissions.downloadPdfAr}
                            downloadPdfWeb={this.state.userPermissions.downloadPdfWeb}
                            downloadImages={this.state.userPermissions.downloadImages}
                            menuFilter={this.state.menuFilter}/>
                    </div>
                </div> :
                    <div style={{textAlign:'center',margin:'1em'}}>
                        <h2>Sie haben keine Berechtigung für diese Applikation</h2>
                        <span>Bitte sprechen Sie mit dem Administrator ihres KDB-Händler Accounts</span>
                        <div style={{textAlign:'center',margin:'1em'}}>
                            <Button onClick={this.state.logout} variant="primary">Ausloggen</Button>{' '}
                        </div>
                    </div>
                }
            </React.StrictMode>
        }

    }
}


export default App;