import config from "../client.config";


class ConfigController {

    static getProductKeysMapping() {
        return config.PRODUCT_KEYS_MAPPING;
    }

    static getAPIHostUrl(){
        return config.SERVER_API_URL;
    }

    ConfigController

    static getWebAppQRCodeUrl(){
        return config.WEB_APP_QRCODE_URL;
    }

}

export default ConfigController;