import React, {Component, useState, useRef, useEffect, useQuery} from 'react';

import './style.css';
import config from "./client.config";
import Dropdown from 'react-bootstrap/Dropdown';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
    faFileCsv,
    faSignOut,
    faUser,
    faSearch,
    faDownload, faBuildingUn, faFileZipper, faDatabase
} from '@fortawesome/free-solid-svg-icons'

class Menu extends Component {


    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this); //add 'this' to function to access props within function
        this.decrementPage = this.decrementPage.bind(this); //add 'this' to function to access props within function
        this.incrementPage = this.incrementPage.bind(this); //add 'this' to function to access props within function
        this.handleSearch = this.handleSearch.bind(this); //add 'this' to function to access props within function
        this.searchInputKeyUp = this.searchInputKeyUp.bind(this); //add 'this' to function to access props within function
        this.handlePageSelectChange = this.handlePageSelectChange.bind(this); //add 'this' to function to access props within function
        this.downloadTest = this.downloadTest.bind(this); //add 'this' to function to access props within function


        this.downloadCheckbox = React.createRef();

        this.inputRef = React.createRef();
        this.decrementButtonRef = React.createRef();
        this.incrementButtonRef = React.createRef();
        this.pageSelectRef = React.createRef();
        this.searchButtonRef = React.createRef();
        this.manufaturerSelectRef = React.createRef();

        this.state = {
            "filterOptions": props.filterOptions,
            "menuFilter": props.menuFilter
        };

        this.state.downloadImages = props.downloadImages;


        this.state.menuFilter.merchant_id = props.menuFilter.selected_merchant_id;

    }



    /**
     * Updates own state when parent props where updated
     * @param props
     * @returns {{selected_merchant_id: (string|*), menuFilter: *, filterOptions: *}}
     */
    componentDidUpdate(props) {

        // return the new state as object, do not call .setState()
        let newState = {
            menuFilter: props.menuFilter,
            filterOptions: props.filterOptions
        };

        if (newState.menuFilter.pagination_current_page > newState.menuFilter.pagination_max_pages) {
            newState.menuFilter.pagination_current_page = newState.menuFilter.pagination_max_pages;
        }

        this.changeActionButtonsState(true);


        return newState;
    }


    handleSearch(e){
        this.changeActionButtonsState(false);
        this.props.onMenuFilterChanged('searchString',this.inputRef.current.value);
    }

    searchInputKeyUp(e){
        e.preventDefault();
        if (e.key === 'Enter' || e.keyCode === 13) {
            this.changeActionButtonsState(false);
            this.props.onMenuFilterChanged('searchString',this.inputRef.current.value);
        }
    }

    handlePageSelectChange(e) {
        this.changeActionButtonsState(false);
        this.props.onMenuFilterChanged(e.target.id, e.target.value);
    }

    handleChange(e) {
        this.changeActionButtonsState(false);
        this.props.onMenuFilterChanged(e.target.id, e.target.value);
    }

    incrementPage(e) {
        this.changeActionButtonsState(false);
        e.preventDefault();
        let newFilter = this.state.menuFilter;
        if (newFilter.pagination_current_page < newFilter.pagination_max_pages) {
            newFilter.pagination_current_page = newFilter.pagination_current_page + 1;
        }
        this.props.onMenuFilterChanged('pagination_current_page', newFilter.pagination_current_page);

    }

    changeActionButtonsState(enabled=true){
        if(this.incrementButtonRef.current != null){
            this.incrementButtonRef.current.disabled = !enabled;
            this.decrementButtonRef.current.disabled = !enabled;
            this.inputRef.current.disabled = !enabled;
            this.pageSelectRef.current.disabled = !enabled;
            this.searchButtonRef.current.disabled = !enabled;
            this.manufaturerSelectRef.current.disabled = !enabled;
        }
    }

    decrementPage(e) {
        this.changeActionButtonsState(false);
        e.preventDefault();
        let newFilter = this.state.menuFilter;
        if (newFilter.pagination_current_page > 1) {
            newFilter.pagination_current_page = newFilter.pagination_current_page - 1;
        }
        this.props.onMenuFilterChanged('pagination_current_page', newFilter.pagination_current_page);
    }

    downloadTest(){
        var searchString ="";
        var pageEntriesCount = this.state.menuFilter.pagination_max_entries;

        const download_params = new URLSearchParams({
            from: (this.state.menuFilter.pagination_current_page * pageEntriesCount) - pageEntriesCount,
            count: pageEntriesCount,
            merchant_id: encodeURIComponent(this.state.menuFilter.merchant_id),
            manufacturer_id: encodeURIComponent(this.state.menuFilter.manufacturer_id),
            productgroup_id: encodeURIComponent(this.state.menuFilter.productgroup_id),
            productsubgroup_id: encodeURIComponent(this.state.menuFilter.productsubgroup_id),
            product_id: encodeURIComponent(this.state.menuFilter.product_id),
            searchString: searchString
        });

        var url = config.SERVER_API_URL+"/products?"+download_params.toString()+"&outFormat=CSV";
        window.open(url,"_self")
    }

    handleDownloadFile(outFormat="CSV",outFormatTemplate="WEB_APP"){

        let pageEntriesCount = this.state.menuFilter.pagination_max_entries;
        let from = (this.state.menuFilter.pagination_current_page * pageEntriesCount) - pageEntriesCount;
        if(this.downloadCheckbox.current.checked != null && this.downloadCheckbox.current.checked){
            pageEntriesCount = 999999;
            from = 0;
        }
        let searchString = "";
        if(this.inputRef.current != null){
            searchString = this.inputRef.current.value;
        }

        let download_params = new URLSearchParams({
            from: from,
            count: pageEntriesCount,
            outFormat: encodeURIComponent(outFormat),
            outFormatTemplate: encodeURIComponent(outFormatTemplate),
            merchant_id: encodeURIComponent(this.state.menuFilter.merchant_id),
            manufacturer_id: encodeURIComponent(this.state.menuFilter.manufacturer_id),
            productgroup_id: encodeURIComponent(this.state.menuFilter.productgroup_id),
            productsubgroup_id: encodeURIComponent(this.state.menuFilter.productsubgroup_id),
            product_id: encodeURIComponent(this.state.menuFilter.product_id),
            searchString: searchString
        });
        let url = config.SERVER_API_URL+"/products?"+download_params.toString();
        window.open(url,"_self")
    }

    render() {

        var pageEntriesCount = this.state.menuFilter.pagination_max_entries;


        var searchString = "";
        if(this.inputRef.current != null){
            searchString = this.inputRef.current.value;
        }

        console.log("RENDER:");
        console.log(this.state.menuFilter.manufacturer_id);

        console.log(this.props)

        return <div className="menu_form" role="form">
            <Row>
                <Col xs={6}>
                    <h1 className="mainHeadline"><img src={this.props.corporateIdentity.logoLandscape} className="header_logo" alt="Logo"/></h1>
                </Col>
                <Col xs={6}>
                    <span style={{marginBottom:"0.5em",float:"right"}} className={'manuLabel'}>
                                {this.props.corporateIdentity.name}
                        <small><a className={"logoutLink"} href={"#"} onClick={this.props.onLogoutClicked}><FontAwesomeIcon icon={faSignOut} /></a>
                            </small>
                            </span>
                    <span style={{float:"right"}} className={'manuLabelTitle'}><FontAwesomeIcon icon={faUser} /></span>
                </Col>
            </Row>
            <div style={{height:'1em'}}></div>
            <Row>
                <Col xs={4}>
                    <span className={'manuLabelTitle'}><FontAwesomeIcon icon={faBuildingUn} /> Datenlieferant</span>
                    <select className={"form-control"} ref={this.manufaturerSelectRef} id="manufacturer_id" style={{cursor:'pointer','width':'100%','border-radius':'0.2em'}} value={this.state.menuFilter.manufacturer_id}
                            onChange={this.handleChange}>
                        {this.state.filterOptions.manufacturer_entries.map((option) => (
                            <option value={option}>{option}</option>
                        ))}
                    </select>
                </Col>

                <Col xs={8}>
                    <span className={'manuLabelTitle'}><FontAwesomeIcon icon={faDatabase} /> Artikel suchen</span>
                    <input type="text" className="form-control"
                           id="searchString"
                           ref={this.inputRef}
                           style={{marginRight:"0.2em"}}
                           onKeyUp={this.searchInputKeyUp}
                           onChange={this.searchInputKeyUp}
                        //value={this.state.menuFilter.searchString}
                           placeholder="Suchbegriff eingeben"/>
                    <button type="button" style={{width:"auto",marginTop:"-3px"}} className="searchButton btn btn-primary" ref={this.searchButtonRef} href="#"
                            onClick={this.handleSearch}><FontAwesomeIcon icon={faSearch} />
                    </button>
                </Col>
            </Row>
            <div style={{height:'1em'}}></div>
            <Row>
                <Col xs={4} md={2}>
                    <div style={{textAlign:'left'}}>
                        <label htmlFor="pagination_max_entries" style={{marginLeft:'0em',marginTop:'0.2em',marginBottom:'0.5em'}}
                               className="control-label"><small>Gefunden</small> <span className={"searchResultItems"}>{this.state.menuFilter.pagination_entries_count}</span></label>
                    </div>
                </Col>
                <Col md={(true || this.props.downloadCsv === true || this.props.downloadImages === true)? 7 : 10} xs={(this.props.downloadCsv === true || this.props.downloadImages === true)? 6 : 8}>
                    <div style={{marginBottom : '1em',textAlign:'left'}}>
                        <select ref={this.pageSelectRef} id="pagination_max_entries" style={{cursor:'pointer'}} className={'form-control pagenumbers'} value={this.state.menuFilter.pagination_max_entries}
                                onChange={this.handlePageSelectChange}>
                            {this.state.filterOptions.pagination_entry_counts.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        <button className={'pageswitch'} ref={this.decrementButtonRef} style={{marginLeft: "1em"}} href="#" onClick={this.decrementPage}>{" < "}</button>
                        <span> {this.state.menuFilter.pagination_current_page} / {this.state.menuFilter.pagination_max_pages} </span>
                        <button className={'pageswitch'} ref={this.incrementButtonRef} href="#" onClick={this.incrementPage}>{" > "}</button>
                    </div>
                </Col>
                {(true || this.props.downloadCsv === true || this.props.downloadImages === true) &&

                    <Col xs={12} md={3}>
                    <Dropdown style={{width:'100%'}}>
                        <Dropdown.Toggle style={{width:'100%'}} id="dropdown-basic">
                            <FontAwesomeIcon icon={faDownload} /> Download
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.ItemText>
                                <label>
                                    <input ref={this.downloadCheckbox} type={"checkbox"}/> gesamte Datenbank
                                </label>
                            </Dropdown.ItemText>
                            {this.props.downloadCsv === true &&
                                <Dropdown.Item onClickCapture={() => this.handleDownloadFile("CSV","")}>
                                    <FontAwesomeIcon icon={faFileCsv} /> CSV-Datei
                                </Dropdown.Item>
                            }
                            {this.props.downloadImages === true &&
                                <Dropdown.Item onClickCapture={() => this.handleDownloadFile("IMAGES_ZIP","")}>
                                    <FontAwesomeIcon icon={faFileZipper} /> Bilder (Zip-Archiv)
                                </Dropdown.Item>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                }

            </Row>
        </div>

    }
}


export default Menu;