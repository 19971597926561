import {forwardRef,useState,useImperativeHandle} from "react";
import {Button, Modal} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faExternalLink,
    faFileCsv,
    faFileImage,
    faFilePdf,
    faFileZipper, faHashtag, faQrcode
} from "@fortawesome/free-solid-svg-icons";
import ApiController from "../controller/APIController";
import ConfigController from "../controller/ConfigController";


const ProductDetailsPopup = forwardRef((props, ref) => {

    const [show, setShow] = useState(props.show);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const product = props.product;
    const downloadCsv = props.downloadCsv;
    const downloadPdfAr = props.downloadPdfAr;
    const downloadPdfWeb = props.downloadPdfWeb;
    const downloadImages = props.downloadImages;
    const merchantId = props.merchantId;
    const priceConsumerBeautified = props.priceConsumerBeautified;

    // We need to wrap component in `forwardRef` in order to gain
    // access to the ref object that is assigned using the `ref` prop.
    // This ref is passed as the second parameter to the function component.
    useImperativeHandle(ref, () => ({
        handleShow() {
            handleShow();
        }
    }));

    function handleDownloadFile(outFormat="CSV",outFormatTemplate="WEB_APP"){
        ApiController.handleDownload(merchantId,product,outFormat);
    }

    var counter = 0;

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 style={{float:'left'}}><FontAwesomeIcon icon={faHashtag} /> {product.ARTIKELNUMMER}</h5>
                    <h5 style={{float:'right'}}>{priceConsumerBeautified}</h5>
                    <div style={{clear:'both'}}></div>
                    <div>
                        {product.image_files.large.map((image_url, i) => {
                            var filename = image_url.substring(image_url.lastIndexOf('/') + 1);
                            return (
                                <div class={'imagebox'}>
                                    {(downloadImages === true) ?
                                        <div>
                                            <Dropdown className="qrcodeImageDownloadDropdown">
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <img title={filename} alt={filename} height={'150px'}
                                                         src={image_url}/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item target={"_blank"}
                                                                   href={product.image_files.highResolution[i]}>
                                                        <FontAwesomeIcon icon={faFileImage}/> Hochauflösend
                                                    </Dropdown.Item>
                                                    <Dropdown.Item target={"_blank"} href={product.image_files.large[i]}>
                                                        <FontAwesomeIcon icon={faFileImage}/> Normal
                                                    </Dropdown.Item>
                                                    <Dropdown.Item target={"_blank"}
                                                                   href={product.image_files.thumbnail[i]}>
                                                        <FontAwesomeIcon icon={faFileImage}/> Vorschaubild
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> :
                                        <div>
                                            <img title={filename} alt={filename} height={'60px'} src={image_url}/>
                                        </div>
                                    }
                                </div>)
                        })}

                    </div>
                    <div style={{clear:'both'}}></div>
                    <h5>
                        Informationen zur Verpackung
                    </h5>
                    <table className={"detailsTable"}>
                        {product.STUECK_PRO_KARTON != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>Stück pro Karton</td>
                                <td>{product.STUECK_PRO_KARTON}</td>
                            </tr>
                        }
                        {product.M2_PRO_KARTON != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>m<sup>2</sup> pro Karton</td>
                                <td>{product.M2_PRO_KARTON}</td>
                            </tr>
                        }
                        {product.ME_PRO_KARTON != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>ME pro Karton</td>
                                <td>{product.ME_PRO_KARTON}</td>
                            </tr>
                        }
                        {product.KG_PRO_KARTON != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>kg pro Karton</td>
                                <td>{product.KG_PRO_KARTON}</td>
                            </tr>
                        }
                        {product.KARTON_PRO_PALETTE != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>Karton pro Palette</td>
                                <td>{product.KARTON_PRO_PALETTE}</td>
                            </tr>
                        }
                        {product.M2_PRO_PALETTE != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>m<sup>2</sup> pro Palette</td>
                                <td>{product.M2_PRO_PALETTE}</td>
                            </tr>
                        }
                        {product.KG_PRO_PALETTE != "" &&
                            <tr className={(counter++%2)==0?"evenClass":"oddClass"}>
                                <td>kg pro Palette</td>
                                <td>{product.KG_PRO_PALETTE}</td>
                            </tr>
                        }
                    </table>
                    <br />
                    <div style={{display:"inline-block",height:"2em",verticalAlign:"middle",marginRight:"1em"}}>
                        <FontAwesomeIcon icon={faQrcode} /> KDB-ID
                    </div>
                    <div style={{display:"inline-block",height:"2em",verticalAlign:"middle"}}>
                        <h5 style={{float:'left',margin:0}}>{product.uniqueId}</h5>
                    </div>
                </Modal.Body>
                {props.showFooter &&
                    <Modal.Footer>
                        {(downloadCsv === true
                                || downloadPdfAr === true
                                || downloadPdfWeb === true
                                || downloadImages === true) &&
                            <Dropdown >
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FontAwesomeIcon icon={faDownload}/> Download
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                    {downloadPdfWeb === true &&
                                        <Dropdown.Item
                                            onClickCapture={() => handleDownloadFile("PDF", "WEB_APP")}>
                                            <FontAwesomeIcon icon={faFilePdf}/> QR-Codes (Web-App)
                                        </Dropdown.Item>
                                    }
                                    {downloadImages === true &&
                                        <Dropdown.Item onClickCapture={() => handleDownloadFile("IMAGES_ZIP", "")}>
                                            <FontAwesomeIcon icon={faFileZipper}/> Bilder (Zip-Archiv)
                                        </Dropdown.Item>
                                    }

                                    {downloadCsv === true &&
                                        <Dropdown.Item onClickCapture={() => handleDownloadFile("CSV", "")}>
                                            <FontAwesomeIcon icon={faFileCsv}/> CSV-Datei
                                        </Dropdown.Item>
                                    }
                                    {downloadPdfAr === true &&
                                        <Dropdown.Item
                                            onClickCapture={() => handleDownloadFile("PDF", "AR_APP")}>
                                            <FontAwesomeIcon icon={faFilePdf}/> QR-Codes (AR-App)
                                        </Dropdown.Item>
                                    }

                                </Dropdown.Menu>
                                {downloadPdfWeb === true &&
                                    <Button style={{marginLeft:'0.5em'}}  variant="primary" onClick={()=>window.open(
                                        ConfigController.getWebAppQRCodeUrl()+"/"+product.uniqueId)}>
                                        <FontAwesomeIcon icon={faExternalLink}/> Web-App Anzeigen
                                    </Button>
                                }
                            </Dropdown>
                        }
                    </Modal.Footer>
                }
            </Modal>
        </>
    );



});



export default ProductDetailsPopup;