import {Component} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class ProductsTableResponsiveWrapper extends Component {

    constructor(props) {
        super(props);
        this.headColumnCells = props.headColumnCells; /* array with Widgets with column length size */
        this.productsArray = props.productsArray; /*list of all products, which will be rendered per line */
        this.productCellWidgetsArray = props.productCellWidgetsArray; /* function to generate */
    }


    renderDesktopTable(){
        let counter = 1;
        const self = this;
        return (
            <div className="productsTableBody desktop">
                <table className="table">
                    <thead>
                    <tr  className={(counter++ % 2) == 0 ? "evenClass" : "oddClass"}>
                        {this.props.headColumnCells.map(function (headColumnCell, idx) {
                            return(
                                <td key={"tablerowhead_"+idx} >
                                    <div style={{paddingTop: '0em', paddingBottom: '0em'}}>
                                        {headColumnCell}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.productCellWidgetsArray.map(function (productCellWidgets, idx) {
                            return (
                                <tr key={"tablerowcell_"+idx}  className={(counter++ % 2) == 0 ? "evenClass" : "oddClass"}>
                                    {productCellWidgets.map(function (productCellWidget, cellIdx) {
                                            return (
                                                <td key={"tablerowcell_"+idx+"_"+cellIdx} >{productCellWidget}</td>
                                            )
                                        }
                                    )}
                                </tr>
                            )
                        }
                    )}
                    </tbody>
                </table>
            </div>
        )
}

renderMobileTable(){
    let counter = 0;
    const self = this;
    return (
        <div className="productsTableBody mobile">
                {this.props.productCellWidgetsArray.map(function (productCellWidgets, idx) {
                        return (
                                <Row className={(counter++ % 2) == 0 ? "tablerow evenClass" : "tablerow oddClass"}>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={12}>
                                                <div style={{textAlign:"left",padding:"0.5em"}}>
                                                    <div className={"mobileProductArtikeltext"}>
                                                        {productCellWidgets[1]}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={8}>
                                                <div style={{textAlign:"left",padding:"0.5em"}}>
                                                    <div style={{display:"inline-block",marginRight:"1em"}}>
                                                        BLP
                                                    </div>
                                                    <div style={{display:"inline-block"}}>
                                                        {productCellWidgets[3]}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                {productCellWidgets[5]}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                        )
                    }
                )}
        </div>
        )
    }

    render() {
        return(
            <div>
                {this.renderDesktopTable()}
                {this.renderMobileTable()}
            </div>
        )
    }
}


export default ProductsTableResponsiveWrapper;