import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {LoginWrapper} from './LoginWrapper';

import Keycloak from 'keycloak-js'
import { useKeycloak } from '@react-keycloak/web'

import { ReactKeycloakProvider } from '@react-keycloak/web'

const keycloak = new Keycloak({
    realm: "master",
    url: "https://login.k-db.de",
    clientId: "kdb",
})


const eventLogger = (event, error) => {
    console.log(event,error);
}

const tokenLogger = (tokens) => {
    console.log("onKeycloakTokens",tokens);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ReactKeycloakProvider authClient={keycloak}
                           initOptions={{
                               onLoad: 'login-required',
                           }}
                           onEvent={eventLogger}
                           onTokens={tokenLogger}>
        <LoginWrapper></LoginWrapper>
    </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
