import * as React from 'react'
import App from './App';

import { useKeycloak } from '@react-keycloak/web'

export const LoginWrapper = () => {
    const { initialized , keycloak} = useKeycloak()
    if (!initialized) {
        return <div>Loading...</div>
    }
    return (
        <div>
            <App keycloak={keycloak}></App>
        </div>
    )
}