const config = {
    //SERVER_API_URL : "http://192.168.0.109:8080",
    SERVER_API_URL : "https://api.k-db.de",
    AR_APP_QRCODE_URL : "https://ar.k-db.de",
    WEB_APP_QRCODE_URL : "https://web.k-db.de",
    //WEB_APP_QRCODE_URL : "http://192.168.0.101:3001",

    PRODUCT_KEYS_MAPPING: {
        manufacturer_id : 'HERSTELLER',
        productgroup_id : 'SERIENNAME',
        productsubgroup_id : 'HERSTELLER_FARBE',
        product_id : 'ARTIKELNUMMER'
    },
};

export default config;
