import {Component} from "react";

class AuslaufArtikelIcon extends Component {
    constructor(props) {
        super(props);
        this.abgelaufen = props.abgelaufen;

    }

    render() {
        return(
            <><span title={this.abgelaufen?"Artikel ist ein Auslaufartikel":"Artikel ist kein Auslaufartikel"}
                    className={this.abgelaufen?"abgelaufenIcon abgelaufen":"abgelaufenIcon aktiv"}></span></>
        );
    }
}


export default AuslaufArtikelIcon;