import ProductsTable from "../ProductsTable";
import ConfigController from "./ConfigController";

const config = require("../client.config");


class APIController {

    static getProductKeysMapping() {
        return config.PRODUCT_KEYS_MAPPING;
    }

    static async fetchSingleProductByProductId(productId = 'HDJ7JD8'){
        const params = new URLSearchParams({
            productId: productId
        });

        Con

        let response = await fetch(config.API_HOST_URL + "/products/single?" + params.toString());
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

    static handleDownload(merchantId,product,outFormat="CSV",outFormatTemplate="WEB_APP"){
        let download_params = new URLSearchParams(
            {
                outFormat: encodeURIComponent(outFormat),
                outFormatTemplate: encodeURIComponent(outFormatTemplate),
                merchant_id: encodeURIComponent(merchantId),
                manufacturer_id: encodeURIComponent(product[ConfigController.getProductKeysMapping().manufacturer_id]),
                productgroup_id: encodeURIComponent(product[ConfigController.getProductKeysMapping().productgroup_id]),
                productsubgroup_id: encodeURIComponent(product[ConfigController.getProductKeysMapping().productsubgroup_id]),
                product_id: encodeURIComponent(product[ConfigController.getProductKeysMapping().product_id]),
                searchString: product.uniqueId
            }
        );
        let url = ConfigController.getAPIHostUrl()+"/products?"+download_params.toString();
        window.open(url,"_self");
    }

    static async getCorporateIdentityByMerchantId(merchantId) {
        const params = new URLSearchParams({
            merchantId: encodeURIComponent(merchantId)
        });
        let url = ConfigController.getAPIHostUrl() + "/users/corporate_identity?" + params.toString();
        let response = await fetch(url);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

    static async updateUserPrices(merchantId,prices) {
        var data = new FormData();
        data.append( "json", JSON.stringify( prices ) );
        const params = new URLSearchParams({
            merchantId: encodeURIComponent(merchantId)
        });
        let url = ConfigController.getAPIHostUrl() + "/users/prices?" + params.toString();
        let response = await fetch(url,{
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(prices)
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

    /**
     *
     * @param merchantId
     * @param beautifyBruttoPrices true or false if the merchant wants to use beautified prices or not
     * @returns {Promise<any>}
     */
    static async updateUserPricesBeautifyMode(merchantId,beautifyBruttoPrices = false) {
        const params = new URLSearchParams({
            merchantId: encodeURIComponent(merchantId),
            beautifyPrices: encodeURIComponent(beautifyBruttoPrices)
        });
        let url = ConfigController.getAPIHostUrl() + "/users/prices/beautify?" + params.toString();
        let response = await fetch(url,{
            method: 'GET',
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

    /**
     * @param merchantId
     * @returns {Promise<beautifyBruttoPrices true or false if the merchant wants to use beautified prices or not>}
     */
    static async getUserPricesBeautifyMode(merchantId) {
        const params = new URLSearchParams({
            merchantId: encodeURIComponent(merchantId),
        });
        let url = ConfigController.getAPIHostUrl() + "/users/prices/beautify?" + params.toString();
        let response = await fetch(url,{
            method: 'GET',
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

    static async getUserPrices(merchantId) {
        const params = new URLSearchParams({
            merchantId: encodeURIComponent(merchantId)
        });
        let url = ConfigController.getAPIHostUrl() + "/users/prices?" + params.toString();
        let response = await fetch(url,{
            method: 'GET',
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    }

}

export default APIController;